import { Button } from "../ui/button"
import { FLYNX_LOGO_BASE64 } from "../../lib/logo"
import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { Globe, Menu, X } from "lucide-react"
import { Language } from "../../lib/translations"
import { useLanguage } from "../../hooks/useLanguage"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../ui/dropdown-menu"

export default function Navbar() {
  const { language, setLanguage, t } = useLanguage()
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    const handleHashChange = () => {
      const hash = window.location.hash.substring(1)
      if (hash) {
        const target = document.getElementById(hash)
        if (target) {
          target.scrollIntoView({ behavior: "smooth", block: "start" })
        }
      }
    }

    window.addEventListener("hashchange", handleHashChange)
    handleHashChange()

    return () => {
      window.removeEventListener("hashchange", handleHashChange)
    }
  }, [])

  const navItems = [
    { key: 'solutions', href: 'solucoes' },
    { key: 'about', href: 'sobre' },
    { key: 'contact', href: 'contato' }
  ] as const

  const handleNavClick = (href: string) => {
    const target = document.getElementById(href)
    if (target) {
      target.scrollIntoView({ behavior: "smooth", block: "start" })
      setIsOpen(false)
    }
  }

  return (
    <header className="sticky top-0 z-50 w-full border-b border-border/80 bg-black/95 backdrop-blur supports-[backdrop-filter]:bg-black/60">
      <div className="container flex h-20 max-w-screen-2xl items-center justify-between">
        <Link to="/" className="mr-6 flex items-center space-x-2">
          <div className="flex items-center">
            <img src={FLYNX_LOGO_BASE64 || "/placeholder.svg"} alt="Flynx Logo" className="h-8 md:h-12 w-auto" />
          </div>
        </Link>

        {/* Desktop Navigation */}
        <nav className="hidden flex-1 items-center justify-center space-x-8 text-md font-medium md:flex">
          {navItems.map(({ key, href }) => (
            <Link
              key={key}
              to={`#${href}`}
              className="text-white transition-colors hover:text-primary relative group"
              onClick={(e) => {
                e.preventDefault()
                handleNavClick(href)
              }}
            >
              {t[key]}
              <span className="absolute -bottom-1 left-0 w-0 h-0.5 bg-primary transition-all group-hover:w-full"></span>
            </Link>
          ))}
        </nav>

        {/* Mobile Menu Button */}
        <button 
          className="md:hidden text-white p-2"
          onClick={() => setIsOpen(!isOpen)}
        >
          {isOpen ? <X size={24} /> : <Menu size={24} />}
        </button>

        {/* Desktop Actions */}
        <div className="hidden md:flex items-center gap-4">
          <div>{language === 'pt-BR' ? 'PT' : 'EN'}</div>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="ghost" size="icon">
                <Globe className="h-12 w-12" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end" className="bg-black border-gray-800">
              <DropdownMenuItem 
                className="text-white cursor-pointer" 
                onClick={() => setLanguage('pt-BR')}
              >
                {t.portuguese}
              </DropdownMenuItem>
              <DropdownMenuItem 
                className="text-white cursor-pointer"
                onClick={() => setLanguage('en')}
              >
                {t.english}
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
          <Button
            size="sm"
            className="bg-primary text-[14px] py-4 px-4 text-white hover:bg-primary/90 transition-transform hover:scale-105 rounded-full bg-black border border-primary"
            onClick={() => handleNavClick("contato")}
          >
            {t.contactUs}
          </Button>
        </div>
      </div>

      {/* Mobile Menu */}
      {isOpen && (
        <div className="md:hidden absolute top-20 left-0 right-0 bg-black/95 border-b border-border/40 py-4">
          <nav className="container flex flex-col space-y-4">
            {navItems.map(({ key, href }) => (
              <Link
                key={key}
                to={`#${href}`}
                className="text-white transition-colors hover:text-primary px-4 py-2"
                onClick={(e) => {
                  e.preventDefault()
                  handleNavClick(href)
                }}
              >
                {t[key]}
              </Link>
            ))}
            <div className="flex items-center justify-between px-4 pt-4 border-t border-gray-800">
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button variant="ghost" size="sm">
                    <Globe className="h-5 w-5 mr-2" />
                    {language === 'pt-BR' ? 'PT' : 'EN'}
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent align="start" className="bg-black border-gray-800">
                  <DropdownMenuItem 
                    className="text-white cursor-pointer" 
                    onClick={() => setLanguage('pt-BR')}
                  >
                    {t.portuguese}
                  </DropdownMenuItem>
                  <DropdownMenuItem 
                    className="text-white cursor-pointer"
                    onClick={() => setLanguage('en')}
                  >
                    {t.english}
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
              <Button
                size="sm"
                className="bg-primary text-sm py-2 px-4 text-white hover:bg-primary/90 rounded-full bg-black border border-primary"
                onClick={() => handleNavClick("contato")}
              >
                {t.contactUs}
              </Button>
            </div>
          </nav>
        </div>
      )}
    </header>
  )
}

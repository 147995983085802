import { useState } from "react"
import { Button } from "./ui/button"
import { Input } from "./ui/input"
import { Textarea } from "./ui/textarea"
import { Label } from "./ui/label"
import { useLanguage } from "../hooks/useLanguage"
export default function CTA() {
  const { t } = useLanguage()
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [company, setCompany] = useState("")
  const [message, setMessage] = useState("")

  const isFormValid = name && email && company && message

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    if (isFormValid) {
      const subject = encodeURIComponent(`${t.contact_subject} - ${name} ${t.from} ${company}`)
      const body = encodeURIComponent(`${t.name}: ${name}\n${t.company}: ${company}\n${t.message}: ${message}`)
      window.location.href = `mailto:contato@flynx.com.br?subject=${subject}&body=${body}`
    }
  }

  return (
    <section id="contato" className="relative bg-black border-t border-gray-800">
      <div className="absolute inset-0" />
      <div className="container relative flex flex-col items-center gap-8 py-24 text-center md:py-32">
        <h2 className="font-bold text-3xl leading-tight sm:text-4xl md:text-5xl max-w-3xl">
          <span className="bg-gradient-to-r from-primary to-purple-400 bg-clip-text text-transparent">{t.contact_title}</span>
        </h2>
        <p className="text-sm md:text-lg text-gray-300 max-w-2xl">
          {t.contact_description}
        </p>
        <form onSubmit={handleSubmit} className="w-full max-w-4xl space-y-6 mt-8">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div className="space-y-4">
              <div className="space-y-2">
                <Label htmlFor="name" className="text-left block text-sm font-medium text-gray-200">
                  {t.name}
                </Label>
                <Input
                  id="name"
                  type="text"
                  placeholder={t.name}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                  className="bg-gray-900/50 border-gray-800 text-white placeholder-gray-500 focus:ring-primary focus:border-primary transition-all duration-300"
                />
              </div>
              <div className="space-y-2">
                <Label htmlFor="email" className="text-left block text-sm font-medium text-gray-200">
                  {t.email}
                </Label>
                <Input
                  id="email"
                  type="email"
                  placeholder={t.email}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  className="bg-gray-900/50 border-gray-800 text-white placeholder-gray-500 focus:ring-primary focus:border-primary transition-all duration-300"
                />
              </div>
              <div className="space-y-2">
                <Label htmlFor="company" className="text-left block text-sm font-medium text-gray-200">
                  {t.company}
                </Label>
                <Input
                  id="company"
                  type="text"
                  placeholder={t.company}
                  value={company}
                  onChange={(e) => setCompany(e.target.value)}
                  required
                  className="bg-gray-900/50 border-gray-800 text-white placeholder-gray-500 focus:ring-primary focus:border-primary transition-all duration-300"
                />
              </div>
            </div>
            <div className="space-y-4">
              <div className="space-y-2">
                <Label htmlFor="message" className="text-left block text-sm font-medium text-gray-200">
                  {t.message}
                </Label>
                <Textarea
                  id="message"
                  placeholder={t.message}
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  required
                  className="bg-gray-900/50 border-gray-800 text-white placeholder-gray-500 focus:ring-primary focus:border-primary transition-all duration-300"
                  rows={8}
                />
              </div>
            </div>
          </div>
          <Button
            type="submit"
            disabled={!isFormValid}
            className={`w-[50%] ${
              isFormValid
                ? "bg-gradient-to-r from-primary to-purple-600 hover:from-primary/90 hover:to-purple-600/90 shadow-lg shadow-primary/20"
                : "bg-gray-800 cursor-not-allowed"
            } text-white font-semibold py-3 px-6 rounded-md transition-all duration-300 transform hover:scale-[1.02] text-lg`}
          >
            {t.send_message}
          </Button>
        </form>
      </div>
    </section>
  )
}


import { Globe } from "../Globe"
import { User } from "lucide-react"
import { useLanguage } from "../../hooks/useLanguage"

export default function About() {
  const { t } = useLanguage()

  const founders = [
    { name: t.aboutFounder1Name, role: t.aboutFounder1Description },
    { name: t.aboutFounder2Name, role: t.aboutFounder2Description },
    { name: t.aboutFounder3Name, role: t.aboutFounder3Description },
  ]

  return (
    <section id="sobre" className="py-20 border-t border-gray-800">
      <div className="container mx-auto px-4">
        <h2 className="text-2xl md:text-4xl font-bold text-center mb-8 text-white">
          <span className="bg-gradient-to-r from-primary to-purple-400 bg-clip-text text-transparent">
            {t.aboutTitle}
          </span>{" "}
          {t.aboutSubtitle}
        </h2>
        <p className="text-sm md:text-lg text-white text-center mb-12 max-w-3xl mx-auto leading-relaxed">
          {t.aboutDescription}
        </p>

        <div className="mb-16 text-center">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {founders.map((founder, index) => (
              <div
                key={index}
                className="bg-black p-6 rounded-lg border border-gray-800 hover:border-primary transition-colors duration-300"
              >
                <User className="w-12 h-12 md:w-16 md:h-16 mx-auto mb-4 text-primary" />
                <h3 className="text-lg md:text-xl font-semibold mb-2 text-white">{founder.name}</h3>
                <p className="text-sm md:text-base text-gray-400">{founder.role}</p>
              </div>
            ))}
          </div>
        </div>

        <h3 className="text-2xl md:text-3xl font-bold text-center mb-12 text-white border-t border-gray-900 pt-8">{t.aboutGlobalTitle}</h3>
        <div className="flex justify-center">
          <div className="w-full max-w-[1800px]">
            <Globe />
          </div>
        </div>
      </div>
    </section>
  )
}


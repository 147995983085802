import { Link } from "react-router-dom"
import { Instagram, Linkedin, Mail, PhoneIcon as WhatsApp } from "lucide-react"
import { FLYNX_LOGO_BASE64 } from "../lib/logo"
import { useLanguage } from "../hooks/useLanguage"

export default function Footer() {
  const { t } = useLanguage()
  return (
    <footer className="border-t border-gray-800 bg-black">
      <div className="container flex flex-col gap-8 py-8 md:flex-row md:py-12">
        <div className="flex-1 space-y-4">
            <Link to="/" className="inline-block">
            <img src={FLYNX_LOGO_BASE64 || "/placeholder.svg"} alt="Flynx Logo" className="h-8" />
          </Link>
          <p className="text-sm text-gray-400">{t.footerDescription}</p>
        </div>
        <div className="grid flex-1 grid-cols-1 gap-12 sm:grid-cols-2">
          <div className="space-y-4">
            <h3 className="text-sm font-medium text-white">{t.footerContactTitle}</h3>
            <ul className="space-y-3 text-sm">
              <li className="flex items-center">
                <Mail className="h-5 w-5 mr-2 text-primary" />
                <a href="mailto:contato@flynx.com.br" className="text-gray-400 hover:text-primary">
                  contato@flynx.com.br
                </a>
              </li>
              <li className="flex items-center">
                <WhatsApp className="h-5 w-5 mr-2 text-primary" />
                <a
                  href="https://wa.me/5541984152304"
                  className="text-gray-400 hover:text-primary"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  +55 (41) 98415-2304
                </a>
              </li>
            </ul>
          </div>
          <div className="space-y-4">
            <h3 className="text-sm font-medium text-white">{t.footerSocialTitle}</h3>
            <div className="flex space-x-4">
              <Link
                to="https://instagram.com/flynxdev"
                className="text-gray-400 hover:text-primary"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Instagram className="h-6 w-6" />
                <span className="sr-only">Instagram</span>
              </Link>
              <Link
                to="https://linkedin.com/company/flynxdevelopment"
                className="text-gray-400 hover:text-primary"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Linkedin className="h-6 w-6" />
                <span className="sr-only">LinkedIn</span>
              </Link>
              <Link
                to="https://wa.me/5541984152304"
                className="text-gray-400 hover:text-primary"
                target="_blank"
                rel="noopener noreferrer"
              >
                <WhatsApp className="h-6 w-6" />
                <span className="sr-only">WhatsApp</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="container border-t border-gray-800 py-6">
        <p className="text-center text-sm text-gray-400">
          © {new Date().getFullYear()} Flynx Tecnologia e Inovação LTDA. CNPJ: 58.550.524/0001-36
        </p>
      </div>
    </footer>
  )
}


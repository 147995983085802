import Navbar from "../../components/Navbar"
import Hero from "../../components/Hero"
import Features from "../../components/Features"
import About from "../../components/About"
import CTA from "../../components/CTA"
import Footer from "../../components/Footer"
import WhatsAppButton from "../../components/whatsapp-button"
import "./styles.css"

export default function Home() {
  return (
    <div className="min-h-screen bg-black text-white">
      <Navbar />
      <main>
        <Hero />
        <div className="relative z-10">
          <Features />
          <About />
          <CTA/>
        </div>
      </main>
      <Footer />
      <WhatsAppButton />
    </div>
  )
}


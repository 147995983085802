import { BrowserRouter as Router } from "react-router-dom"
import { LanguageProvider } from "./hooks/useLanguage"
import Home from "./pages/Home"
import './index.css';

function App() {
  return (
    <LanguageProvider>
      <Router>
        <Home />
      </Router>
    </LanguageProvider>
  )
}

export default App
export type Language = 'pt-BR' | 'en';

export const translations = {
  'pt-BR': {
    // Navbar
    solutions: 'Soluções',
    about: 'Sobre',
    contact: 'Contato',
    contactUs: 'Entrar em Contato',
    portuguese: 'Português',
    english: 'English',

    // Hero
    heroTitle: 'Inovação Tecnológica',
    heroSubtitle: 'Transformando ideias em soluções digitais poderosas para impulsionar seu negócio.',
    heroContact: 'Entrar em Contato',
    heroLearnMore: 'Conhecer Nossas Soluções',

    // Features
    featuresTitle: 'Nossas Soluções',
    featuresDescription: 'Descubra como a Flynx pode transformar seu negócio com nossas tecnologias inovadoras.',
    feature_1_name: "Desenvolvimento de Software Personalizado",
    feature_1_description:
      "Criamos soluções sob medida que impulsionam a eficiência operacional e oferecem vantagens competitivas únicas para o seu negócio.",
    feature_2_name: "Aplicativos Móveis de Alta Performance",
    feature_2_description:
      "Desenvolvemos apps intuitivos e robustos que proporcionam experiências excepcionais e engajam seus usuários em todas as plataformas.",
    feature_3_name: "Integração Avançada de IA",
    feature_3_description:
      "Implementamos soluções de inteligência artificial de ponta que otimizam processos, fornecem insights valiosos e impulsionam a inovação em sua empresa.",
    feature_4_name: "Desenvolvimento de MVP",
    feature_4_description:
      "Transformamos sua ideia em um pequeno piloto em um curto período de tempo, permitindo validar conceitos e acelerar o time-to-market.",
    footerDescription: 'Soluções tecnológicas inovadoras para o seu negócio.',
    footerContactTitle: 'Contato',
    footerSocialTitle: 'Redes Sociais',
    aboutTitle: 'Inovação e Excelência',
    aboutSubtitle: 'em Tecnologia',
    aboutDescription: 'Fundada em 2023 em Curitiba, PR, por três sócios com experiência internacional, a Flynx nasceu para revolucionar o cenário tecnológico. Nossa missão é simplificar a aplicação de IA e acelerar a entrega de MVPs, oferecendo soluções ágeis e inovadoras que impulsionam o sucesso dos nossos clientes.',
    aboutFounder1Name: 'Guilherme Seguro',
    aboutFounder1Description: 'Fundador e CTO (Diretor de Tecnologia)',
    aboutFounder2Name: 'Pedro Cardoso',
    aboutFounder2Description: 'Co-fundador e CSO (Diretor de Vendas)',
    aboutFounder3Name: 'Tobias Gelasko',
    aboutFounder3Description: 'CCO (Diretor Comercial)',
    aboutGlobalTitle: 'Nossa Presença Global',
    aboutGlobalUnitedStates: 'Estados Unidos',
    aboutGlobalBrazil: 'Brasil',
    aboutGlobalUnitedStatesDescription: 'Desenvolvimento Web3 e integrações com IA',
    aboutGlobalBrazilDescription: 'Soluções de software personalizadas',
    aboutGlobalProjects1: 'Desenvolvimento Web3',
    aboutGlobalProjects2: 'Integrações com IA',
    aboutGlobalProjects3: 'Soluções de software personalizadas',
    aboutGlobalBrazilProjects1: 'Desenvolvimento de software personalizado',
    aboutGlobalBrazilProjects2: 'Aplicativos móveis de alta performance',
    aboutGlobalBrazilProjects3: 'Integração avançada de IA',
    aboutGlobalProjectsTitle: 'Projetos Destacados',
    contact_subject: 'Contato via site',
    name: 'Nome',
    company: 'Empresa',
    message: 'Mensagem',
    from: 'de',
    contact_title: 'Vamos conversar?',
    send_message: 'Enviar Mensagem',
    contact_description: 'Entre em contato e agende uma reunião para discutir como a Flynx pode ajudar a impulsionar a inovação no seu negócio.',
    contact_name: 'Nome',
    contact_company: 'Empresa',
    contact_message: 'Mensagem',
    contact_from: 'de',
    email: 'Email',
    phone: 'Telefone',
    address: 'Endereço',
    contact_info: 'Informações de Contato',
    contact_info_title: 'Informações de Contato',
    contact_info_address: 'Endereço',
  },
  'en': {
    // Navbar
    solutions: 'Solutions',
    about: 'About',
    contact: 'Contact',
    contactUs: 'Contact Us',
    portuguese: 'Portuguese',
    english: 'English',
    // Hero

    heroTitle: 'Innovating with Technology',
    heroSubtitle: 'Transforming ideas into powerful digital solutions to drive your business.',
    heroContact: 'Contact Us',
    heroLearnMore: 'Discover Our Solutions',

    // Features
    featuresTitle: 'Our Solutions',
    featuresDescription: 'Discover how Flynx can transform your business with our innovative technologies.',
    feature_1_name: "Custom Software Development",
    feature_1_description:
      "We create custom solutions that drive operational efficiency and provide unique competitive advantages for your business.",
    feature_2_name: "High-Performance Mobile Applications",
    feature_2_description:
      "We develop intuitive and robust apps that deliver exceptional experiences and engage users across all platforms.",
    feature_3_name: "Advanced AI Integration",
    feature_3_description:
      "We implement cutting-edge AI solutions that optimize processes, provide valuable insights, and drive innovation in your business.",
    feature_4_name: "MVP Development",
    feature_4_description:
      "We transform your idea into a small pilot in a short period of time, allowing you to validate concepts and accelerate the time-to-market.",
    footerDescription: 'Innovative technological solutions for your business.',
    footerContactTitle: 'Contact',
    footerSocialTitle: 'Social',
    aboutTitle: 'Innovation and Excellence',
    aboutSubtitle: 'in Technology',
    aboutDescription: 'Founded in 2023 in Parana, Brazil, by three partners with international experience, Flynx was born to revolutionize the technological landscape. Our mission is to simplify the application of AI and accelerate the delivery of MVPs, offering agile and innovative solutions that drive the success of our clients.',
    aboutFounder1Name: 'Guilherme Seguro',
    aboutFounder1Description: 'Founder and CTO (Technology Director)',
    aboutFounder2Name: 'Pedro Cardoso',
    aboutFounder2Description: 'Co-founder and CSO (Sales Director)',
    aboutFounder3Name: 'Tobias Gelasko',
    aboutFounder3Description: 'CCO (Commercial Director)',
    aboutGlobalTitle: 'Our Global Presence',
    aboutGlobalUnitedStates: 'United States',
    aboutGlobalBrazil: 'Brazil',
    aboutGlobalUnitedStatesDescription: 'Web3 development and AI integrations',
    aboutGlobalBrazilDescription: 'Custom software solutions',
    aboutGlobalProjects1: 'Web3 development',
    aboutGlobalProjects2: 'AI integrations',
    aboutGlobalProjects3: 'Custom software solutions',
    aboutGlobalBrazilProjects1: 'Custom software solutions',
    aboutGlobalBrazilProjects2: 'High-performance mobile applications',
    aboutGlobalBrazilProjects3: 'Advanced AI integrations',
    aboutGlobalProjectsTitle: 'Featured Projects',
    contact_subject: 'Contact via website',
    name: 'Name',
    company: 'Company',
    message: 'Message',
    from: 'from',
    contact_title: 'Let\'s talk?',
    send_message: 'Send Message',
    contact_description: 'Contact us and schedule a meeting to discuss how Flynx can help drive innovation in your business.',
    contact_name: 'Name',
    contact_company: 'Company',
    contact_message: 'Message',
    contact_from: 'from',
    email: 'Email',
    phone: 'Phone',
    address: 'Address',
    contact_info: 'Contact Information',
    contact_info_title: 'Contact Information',
    contact_info_address: 'Address',
    contact_info_phone: 'Phone',
  }
} as const;

export type TranslationKey = keyof typeof translations['pt-BR']; 
import { Button } from "./ui/button"
import { ArrowRight } from "lucide-react"
import NeuralNetworkEffect from "./neural-network-effect"
import { useLanguage } from "../hooks/useLanguage"

export default function Hero() {
  const { t } = useLanguage()
  const handleNavClick = (href: string) => {
    const target = document.getElementById(href)
    if (target) {
      target.scrollIntoView({ behavior: "smooth", block: "start" })
    }
  }
  return (
    <section className="relative overflow-hidden border-b border-gray-800">
      <div className="container flex min-h-[calc(100vh-4rem)] max-w-screen-xl flex-col items-center justify-center space-y-8 py-24 text-center md:py-32">
        <div className="relative z-10 space-y-8">
          <h1 className="text-4xl font-bold tracking-tight sm:text-5xl md:text-6xl">
            <span className="inline-block animate-pulse text-primary">&lt;/&gt;</span>{" "}
            <span className="bg-gradient-to-r from-white via-primary to-purple-400 bg-clip-text text-transparent animate-text">
              {t.heroTitle}
            </span>
          </h1>
          <p className="mx-auto max-w-[42rem] text-lg leading-normal text-gray-300 sm:text-xl animate-fade-in-up">
            {t.heroSubtitle}
          </p>
          <div className="flex flex-col items-center justify-center gap-4 sm:flex-row animate-fade-in-up">
            <Button
              size="lg"
              className="bg-gradient-to-r from-primary to-purple-600 text-white hover:from-primary/90 hover:to-purple-600/90 transition-all duration-300 ease-in-out transform hover:scale-105"
              onClick={() => handleNavClick('contato')}
            >
              {t.heroContact}
              <ArrowRight className="ml-2 h-4 w-4" />
            </Button>
            <Button
              variant="outline"
              size="lg"
              onClick={() => handleNavClick('solucoes')}
              className="text-white border-primary hover:text-white hover:bg-primary/10 transition-all duration-300 ease-in-out transform hover:scale-105"
            >
              {t.heroLearnMore}
            </Button>
          </div>
        </div>
      </div>
      <div className="absolute inset-0 z-0 opacity-20">
        <NeuralNetworkEffect />
      </div>
    </section>
  )
}


import { Code, Smartphone, Brain, Rocket } from "lucide-react"
import { useLanguage } from "../hooks/useLanguage"

export default function Features() {
  const { t } = useLanguage()

  const features = [
    {
      name: t.feature_1_name,
      description: t.feature_1_description,
      icon: Code,
    },
    {
      name: t.feature_2_name,
      description: t.feature_2_description,
      icon: Smartphone,
    },
    {
      name: t.feature_3_name,
      description: t.feature_3_description,
      icon: Brain,
    },
    {
      name: t.feature_4_name,
      description: t.feature_4_description,
      icon: Rocket,
    },
  ]
  return (
    <section id="solucoes" className="container space-y-16 py-24 md:py-32">
      <div className="mx-auto max-w-[58rem] text-center">
        <h2 className="font-bold text-3xl leading-[1.1] sm:text-3xl md:text-5xl text-white">
          {t.featuresTitle}
        </h2>
        <p className="mt-4 text-gray-400 sm:text-lg">
          {t.featuresDescription}
        </p>
      </div>
      <div className="mx-auto grid max-w-5xl grid-cols-1 gap-8 md:grid-cols-2">
        {features.map((feature) => (
          <div key={feature.name} className="relative overflow-hidden rounded-lg border border-gray-800 bg-black p-8">
            <div className="flex items-center gap-4">
              <feature.icon className="h-8 w-8 text-primary" />
              <h3 className="text-lg md:text-xl font-bold text-white">{feature.name}</h3>
            </div>
            <p className="text-sm pt-2 md:text-base text-gray-400">{feature.description}</p>
          </div>
        ))}
      </div>
    </section>
  )
}


import GlobeGL from 'globe.gl'
import { useEffect, useRef, useState } from 'react'
import { motion } from 'framer-motion'
import { useLanguage } from "../hooks/useLanguage"

type Location = {
  name: string
  lat: number
  lng: number
  size: number
  color: string
  description: string
  projects: string[]
  flag: string
}

export function Globe() {
  const { t } = useLanguage()
  const containerRef = useRef<HTMLDivElement>(null)
  const [activeLocation, setActiveLocation] = useState<Location | null>(null)
  const globeRef = useRef<any>(null)
  const [markerData, setMarkerData] = useState<any[]>([])
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth < 768)
    }
    checkMobile()
    window.addEventListener('resize', checkMobile)
    return () => window.removeEventListener('resize', checkMobile)
  }, [])

  useEffect(() => {
    setMarkerData([
      {
        name: t.aboutGlobalUnitedStates,
        lat: 38,
        lng: -95,
        size: 1.5,
        color: '#6C47FF',
        description: t.aboutGlobalUnitedStatesDescription,
        flag: "🇺🇸",
        projects: [
          t.aboutGlobalProjects1,
          t.aboutGlobalProjects2,
          t.aboutGlobalProjects3,
        ]
      },
      {
        name: t.aboutGlobalBrazil,
        lat: -15.7801,
        lng: -47.9292,
        size: 1.5,
        color: '#6C47FF',
        description: t.aboutGlobalBrazilDescription,
        flag: "🇧🇷",
        projects: [
          t.aboutGlobalBrazilProjects1,
          t.aboutGlobalBrazilProjects2,
          t.aboutGlobalBrazilProjects3,
        ]
      }
    ])
  }, [t])

  useEffect(() => {
    if (!containerRef.current) return

    const container = containerRef.current
    const globe = new GlobeGL(container)
      .globeImageUrl('//unpkg.com/three-globe/example/img/earth-dark.jpg')
      .backgroundColor('rgba(0,0,0,0)')
      .width(container.clientWidth)
      .height(isMobile ? 300 : 500)

    globeRef.current = globe

    if (globeRef.current && markerData.length > 0) {
      globeRef.current
        .pointsData(markerData)
        .pointAltitude(0.1)
        .pointColor((d: any) => (d === activeLocation ? '#FF4747' : d.color))
        .pointRadius((d: any) => (d === activeLocation ? 2 : d.size))
        .pointResolution(64)
        .pointsMerge(false)
        .pointLabel(() => '')
        .onPointClick((point: any, event: any) => {
          event.preventDefault()
          const clickedPoint = markerData.find(
            m => m.lat === point.lat && m.lng === point.lng
          )
          if (clickedPoint) {
            setActiveLocation(clickedPoint)
            globe.controls().autoRotate = true
            globe.pointOfView({ lat: clickedPoint.lat, lng: clickedPoint.lng, altitude: 2.5 }, 1000)
          }
        })

      globe.controls().autoRotate = true
      globe.controls().autoRotateSpeed = 0.3
      globe.controls().enableZoom = false

      const brazilPoint = markerData.find(point => point.name === t.aboutGlobalBrazil)
      if (brazilPoint) {
        setActiveLocation(brazilPoint)
        globe.pointOfView({ lat: brazilPoint.lat, lng: brazilPoint.lng, altitude: 2.5 }, 0)
      }

      let currentPointIndex = 1
      setInterval(() => {
        if (!activeLocation) {
          currentPointIndex = (currentPointIndex + 1) % markerData.length
          const point = markerData[currentPointIndex]
          globe.pointOfView({ lat: point.lat, lng: point.lng, altitude: 2.5 }, 2000)
        }
      }, 10000)

      const handleResize = () => {
        globe.width(container.clientWidth)
        globe.height(isMobile ? 300 : 500)
      }
      window.addEventListener('resize', handleResize)

      return () => {
        window.removeEventListener('resize', handleResize)
        globe._destructor()
        container.innerHTML = ''
      }
    }
  }, [markerData, isMobile])

  useEffect(() => {
    if (globeRef.current) {
      globeRef.current
        .pointColor((d: any) => (d === activeLocation ? '#FF4747' : d.color))
        .pointRadius((d: any) => (d === activeLocation ? 2 : d.size))
    }
  }, [activeLocation])

  return (
    <div className={`flex ${isMobile ? 'flex-col' : 'flex-row'} justify-center items-center md:items-start gap-6 md:gap-12`}>
      <div ref={containerRef} className={`${isMobile ? 'h-[300px] w-full' : 'h-[400px] w-[700px]'} cursor-pointer`} />
      {activeLocation && (
        <motion.div
          initial={{ opacity: 0, x: isMobile ? 0 : 20, y: isMobile ? 20 : 0 }}
          animate={{ opacity: 1, x: 0, y: 0 }}
          exit={{ opacity: 0, x: isMobile ? 0 : 20, y: isMobile ? 20 : 0 }}
          className={`${isMobile ? 'w-full' : 'w-[400px]'} md:mt-24 bg-[#0a0a0a]/80 backdrop-blur-md p-6 md:p-8 rounded-2xl`}
        >
          <div className="space-y-6 md:space-y-8">
            <div>
              <div className="flex items-center gap-3 mb-3">
                <span className="text-xl md:text-2xl">{activeLocation.flag}</span>
                <h3 className="text-xl md:text-2xl font-bold bg-gradient-to-r from-[#6C47FF] to-[#8A6FFF] bg-clip-text text-transparent">
                  {activeLocation.name}
                </h3>
              </div>
              <p className="mt-3 text-gray-400 leading-relaxed text-sm md:text-base">
                {activeLocation.description}
              </p>
            </div>

            <div>
              <h4 className="text-base md:text-lg font-medium text-white/90 mb-4">
                {t.aboutGlobalProjectsTitle}
              </h4>
              <ul className="space-y-4">
                {activeLocation.projects.map((project, index) => (
                  <li
                    key={index}
                    className="flex items-start gap-3 text-gray-300 text-sm md:text-base"
                  >
                    <span className="mt-2 h-1.5 w-1.5 rounded-full bg-[#6C47FF] flex-shrink-0" />
                    {project}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </motion.div>
      )}
    </div>
  )
}